<template>
    <v-card class="secondary">
    <v-card-title>
      Meus Festivais
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-btn dark class="mb-2" :to="newPageRoute" :disabled="!isFestivalProfile.status">
        Novo Festival
      </v-btn>
    </v-card-title>

    <v-alert type="error" v-if="!isFestivalProfile.status && !loading" class="ma-2">
      Lamentamos mas só pode criar Festival quem for "Perfil Festival". De modo a ser "Perfil Festival" faz um pedido
      <a href="http://localhost:8082/perfil/dashboard/festival/autorizacao/nova">aqui</a>.
    </v-alert>

    <v-data-table class="secondary"
      :headers="headers"
      :items="festivals"
      :loading="loading"
      :search="search">
      <template v-slot:[`item.status`]="{ item }">
          <status-chip class="mt-2" :status="item.status == 'APPROVED' ? true : false" />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn class="mr-2" small icon :to="item.$route.detail">
          <v-icon  color="info"  >
            mdi-eye-outline
          </v-icon>
        </v-btn>
        <v-btn small class="mr-2" icon :to="item.$route.edit">
          <v-icon  color="info" >
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn small class="mr-2" icon @click="del(item)">
          <v-icon color="error" small >
            mdi-delete
          </v-icon>
        </v-btn>
        
      </template>
    </v-data-table>
    <v-container v-if="hasMore">
      <v-row align="center" justify="center">
        <v-btn 
          :loading="loading" 
          :disabled="loading"
          @click="fetchMore">Carregue Mais</v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { profileDashboard } from '@/routes';
import StatusChip from '../../../StatusChip';
import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    components: {StatusChip,},
    data () {
      return {
        newPageRoute: profileDashboard.festival.myFestivals.new,
        search: '',
        headers: [
          {
            text: 'Nome',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Categoria',
            align: 'start',
            value: 'category.name',
          },
          {
            text: 'Data Limite de Registo',
            align: 'start',
            value: 'registerDateLimit',
          },
          {
            text: 'Data Publicacão',
            align: 'start',
            value: 'dataPublished',
          },
          {
            text: 'Status',
            value: 'status',
          },
          { text: 'Actions', value: 'action', sortable: false },
        ],
      }
    },
    created () {
        this.fetchItems(this.filter);
        this.getIsFestivalProfile({ id: this.user.id })
    },
    computed: {
        ...mapGetters('dashboard/myFestival', [ 'hasMore']),
        ...mapState('dashboard/myFestival', ['festivals', 'hasMore', 'loading']),
        ...mapState('dashboard/festivalRequest', ['isFestivalProfile']),
        filter() {
            return { 
                limit: 20,
                profile: this.$user.info.id,
            }
        },

        user () {
          return this.$session.get('_yjgkb_');
        }
    },
    methods: {
      ...mapActions('dashboard/myFestival', {
          fetchItems: 'list',
          loadMore: 'loadMore'
      }),
      ...mapActions('dashboard/festivalRequest', {
        getIsFestivalProfile: 'isFestivalProfile'
      }),
      ...mapActions('dashboard/myFestival', ['delete',]),
      fetchMore() {
          this.loadMore(this.filter);
      },
      del(item) {
          if (!confirm("Desejas Eliminar o item selecionado?")) return;

          this.delete({data: item, token: this.$user.info}).then((data) => {
              this.alerts = data.messages;
          }).catch(() => {
              this.alerts = [ {
                  success: false,
                  message: 'Problema no processamento'
              }];
          });
      }
    },
  }
</script>