<template>
    <v-container>
        <List />
    </v-container>
</template>

<script>
import List from '@/components/dashboard/festival/festival/List';
import { HOME } from '@/routes';
export default {
    components: {
        List,
    },
    data() {
       return {}
    },
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
    }
}
</script>