<template>
    <div>
        <td class="justify-center" v-if="status">
            <v-chip pill small>
                <v-avatar left :color="`${ status? 'green': 'red' }`">
                    {{ status ? 'A': 'I' }}
                </v-avatar>
                {{ status ? 'Ativado': 'Inativo' }}
            </v-chip>
        </td>
    </div>
</template>

<script>
export default {
    props: {
        status: {
            type: Boolean,
            default: true
        }
    }
}
</script>